'use client';

import { FC } from 'react';

import { getLanguageCode, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { AspectRatio } from '@ravago/shared/radiance/components/aspect-ratio/components/AspectRatio/AspectRatio';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Container } from '@ravago/shared/radiance/components/container/components/Container/Container';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { Link } from 'libs/shared/page-elements-radiance/src/lib/components/shared/link/link';
import { usePathname } from 'next/navigation';

const PageNotFound: FC = () => {
  const translate = useTranslations();
  const language = getLanguageCode(usePathname().split('/')[1]);

  return (
    <Container>
      <Box spacing={{ top: 'xl' }} direction="column" items="center">
        <BreakpointSwitch includeViews={['phone', 'tab-port', 'tab-land']}>
          <AspectRatio aspectRatio="16/9" width="100%">
            <svg style={{ width: '100%', height: '100%' }}>
              <use href="/illustrations/404.svg#symbol" />
            </svg>
          </AspectRatio>
        </BreakpointSwitch>
        <BreakpointSwitch includeViews={['desktop']}>
          <AspectRatio aspectRatio="32/9" width="100%">
            <svg style={{ width: '100%', height: '100%' }}>
              <use href="/illustrations/404.svg#symbol" />
            </svg>
          </AspectRatio>
        </BreakpointSwitch>
        <Box direction="column" gap="2xs" items="center">
          <Typography align="center" variant="heading-1" component="h1">
            {translate('page-consumer.404.title')}
          </Typography>
          <Typography align="center" component="p">
            {translate('page-consumer.404.description')}
          </Typography>
        </Box>
        <Link underline="none" href={`/${String(language)}`}>
          <Button>{translate('page-consumer.404.button')}</Button>
        </Link>
      </Box>
    </Container>
  );
};

export default PageNotFound;
