import { FC, ReactNode } from 'react';

interface Props {
   children: ReactNode | Array<ReactNode>;
   useContainerQuery?: boolean;
   fullWidth?: boolean;
}

export const Container: FC<Props> = ({ children, useContainerQuery, fullWidth }) => (
   <div data-testid="container" className={`w-full ${useContainerQuery ? '@container' : ''}`}>
      <div className={!fullWidth ? 'container' : ''}>{children}</div>
   </div>
);
